import { lazy } from "react";
import { Navigate } from "react-router-dom";

/****Layouts*****/
const FullLayout = lazy(() => import("../layouts/FullLayout/FullLayout.js"));
/****End Layouts*****/
const History = lazy(() => import("../views/admin/listSMS"));
const ManageUser = lazy(() => import("../views/admin/users"));
const ManageIP = lazy(() => import("../views/admin/manageIP"));
const ManageProvider = lazy(() => import("../views/admin/manageProvider"));
const CreateProvider = lazy(() => import("../views/admin/createProvider"));
const ProviderDetail = lazy(() => import("../views/admin/providerDetail"));
const TestSystem = lazy(() => import("../views/admin/testsystem"));
const Statistic = lazy(() => import("../views/admin/statistic"));

const UserDetail = lazy(() => import("../views/admin/userDetail"));
const CreateUser = lazy(() => import("../views/admin/createUser"));

const Login = lazy(() => import("../views/Login"));
const ChangePassword = lazy(() => import("../views/ChangePassword"));

const ManageSupplier = lazy(() => import("../views/supplier/index.js"));
const CreateSupplier = lazy(() => import("../views/supplier/create.js"));
const EditSupplier = lazy(() => import("../views/supplier/create.js"));

const ManagePurchaseOrder = lazy(() =>
	import("../views/purchase-order/index.js")
);

const HistoryPurchaseOrder = lazy(() =>
	import("../views/purchase-order/history.js")
);
const CreatePurchaseOrder = lazy(() =>
	import("../views/purchase-order/create.js")
);

const EditPurchaseOrder = lazy(() =>
	import("../views/purchase-order/create.js")
);

const ManageProject = lazy(() => import("../views/project/index.js"));
const CreateProject = lazy(() => import("../views/project/create.js"));
const EditProject = lazy(() => import("../views/project/create.js"));

const PaymentRequest = lazy(() => import("../views/payment-request/index.js"));
const CreatePaymentRequest = lazy(() =>
	import("../views/payment-request/create.js")
);
const PaymentRequestHistory = lazy(() =>
	import("../views/payment-request/history.js")
);
/*****Routes******/

const ThemeRoutes = [
	{
		path: "/",
		element: <FullLayout />,
		children: [
			{
				path: "/",
				element: <Navigate to="manage-purchaseorder" />,
			},
			{ path: "/createuser", exact: true, element: <CreateUser /> },
			{ path: "/history", exact: true, element: <History /> },
			{ path: "/manageuser", exact: true, element: <ManageUser /> },
			{ path: "/manageIP", exact: true, element: <ManageIP /> },
			{
				path: "/manage-supplier",
				exact: true,
				element: <ManageSupplier />,
			},
			{
				path: "/create-supplier",
				exact: true,
				element: <CreateSupplier />,
			},
			{
				path: "/edit-supplier/:id",
				exact: true,
				element: <EditSupplier />,
			},
			{
				path: "/manage-project",
				exact: true,
				element: <ManageProject />,
			},
			{
				path: "/create-project",
				exact: true,
				element: <CreateProject />,
			},
			{
				path: "/edit-project/:project_id",
				exact: true,
				element: <EditProject />,
			},
			{
				path: "/create-purchaseorder",
				exact: true,
				element: <CreatePurchaseOrder />,
			},
			{
				path: "/manage-purchaseorder",
				exact: true,
				element: <ManagePurchaseOrder />,
			},
			{
				path: "/edit-purchaseorder/:id",
				exact: true,
				element: <EditPurchaseOrder />,
			},
			{
				path: "/history-po",
				exact: true,
				element: <HistoryPurchaseOrder />,
			},

			{ path: "/userdetail/:id", exact: true, element: <UserDetail /> },
			{
				path: "/providerdetail/:id",
				exact: true,
				element: <ProviderDetail />,
			},
			{ path: "/testsystem", exact: true, element: <TestSystem /> },
			{ path: "/statistic", exact: true, element: <Statistic /> },
			{
				path: "/create-payment-request",
				exact: true,
				element: <CreatePaymentRequest />,
			},
			{
				path: "/manage-payment-request",
				exact: true,
				element: <PaymentRequest />,
			},
			{
				path: "/edit-payment-request/:id",
				exact: true,
				element: <CreatePaymentRequest />,
			},
		],
	},
	{
		path: "/login",
		element: <Login />,
	},
	{
		path: "/change-password",
		element: <ChangePassword />,
	},
];

export default ThemeRoutes;
