import axios from "axios";

// const baseUrl = process.env.REACT_APP_BASE_URL;
// const baseUrl = "http://localhost:6869";
const baseUrl = "https://api.tspsmanagement.com/";

export function config() {
	axios.defaults.baseURL = baseUrl;
	axios.defaults.responseType = "json";
	axios.defaults.headers.common["Content-Type"] = "application/json";
	axios.defaults.headers.common.post = {};
	axios.defaults.headers.common.put = {};
	axios.defaults.xsrfCookieName = "_CSRF";
	axios.defaults.xsrfHeaderName = "CSRF";
}

config();

export default {
	baseUrl: baseUrl,
};
