import React from "react";
import { useRoutes } from "react-router-dom";
import { ThemeProvider } from "@material-ui/core";
import { baseTheme } from "./assets/global/Theme-variable";
import UserRouter from "./routes/UserRouter";
import AdminRouter from "./routes/AdminRouter";

import { useAuth } from "./hooks/useAuth";
import auth from "./services/auth";

const App = () => {
	const { user } = useAuth();
	let currentRoute = UserRouter;

	if (user?.role == "admin") {
		currentRoute = AdminRouter;
	}
	let routing = useRoutes(currentRoute);
	const theme = baseTheme;
	if (user) {
		auth.setAuthorizationToken(user.token);
	}

	return <ThemeProvider theme={theme}>{routing}</ThemeProvider>;
};

export default App;
